@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body,
html {
    /* 👇️ or use Operating system default fonts 👇️ */
    font-family: "Segoe UI";
}



.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #eeeeee;
    box-shadow: "0px 1px 3px #616060c6";
}
.bodycard {
    padding-top: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-right: 0px !important;
    margin-left: 0px !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    overflow-y: auto;
    min-height: calc(-446px + 100vh) !important;
    max-height: calc(-446px + 100vh) !important;
}



.css-1ex1afd-MuiTableCell-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 16px !important;
    color: rgba(0, 0, 0, 0.87);
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: white !important;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: white !important;
    border-top: 1px solid rgba(0, 0, 0, .125);
}

.MuiTableContainer-root {
    overflow-y: auto !important;
    padding-bottom: 0rem !important;
    min-height: 0px;
    overflow-x: auto !important;
}


.jss8 {
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    padding: 8px 16px;
    font-size: 0.875rem;
    text-align: center;
    align-items: center;
    font-family: "Segoe UI", "-apple-system", "Helvetica Neue", "Arial";
    font-weight: 300;
    line-height: 1.3;
    vertical-align: inherit;
    justify-content: center;
    height: 58px;
}


.MuiTableBody-root {
    overflow-y: auto !important;
    overflow-x: auto !important;
    display: block !important;
    max-height: 394px !important;
}
.Headercard {
    background-color: white !important;
    height: 66px !important;
}
/* .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content-wrap {
    flex: 1;
} */
body {
    overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    body {
        overflow-y: auto;
    }
}
@media screen and (max-width: 550px) {
    .footer__clz {
        font-size: 9px;
    }
}

.page-content {
    display: grid;
    grid-template-rows: auto auto 1fr;
}
.container-fluid {
    display: grid;
    grid-template-rows: auto 1fr;
}

.page-wrapper {
    /* background: #eef5f9; */
    position: relative;
    transition: 0.2s ease-in;
    display: block;
    margin-top: 100px;
}

#main-wrapper {
    width: 100%;
    overflow: hidden;
    margin: 0;
}
.sidebar-wrapper {
    position: fixed;
}

.left-sidebar {
    position: absolute;
    width: 220px;
    height: 100%;
    top: 0px;
    z-index: 10;
    padding-top: 0;
    background: rgb(8, 8, 8);
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    /* transition: 0.2s ease-in; */
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    /* transition: transform 0.6s ease; */
    transition-delay: 0.4s;
}
.headercss {
    position: fixed;
    z-index: 10;
    padding: 0;
    margin: 0;
}

.responsive-toast {
    width: 300px;
}

@media (min-width: 993px) {
    .responsive-toast {
        width: 600px;
    }
}

#loading {
  display: inline-block;
  color: rgba(68, 185, 252, 0.3);
  width: 50px;
  height: 50px;
  border: 3px solid rgba(68, 185, 252, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.centeranimation {
  display: flex;
  flex-direction: row;
  margin-top: 20%;
  justify-content: center;
}

#listfilter {
    max-height: calc(-630px + 100vh) !important;
}

.menu__clz {
    width: 0 !important;
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 70px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.dropdown-menu {
 
    min-width: 15rem !important;

}
.language-switch{
    top:5px !important;
    left: 12px !important
}
.header_wrapper__clz {
    position: fixed !important;
}
.styled_h1__clz {
    width: 71px !important;
}
.footer__clz {
    margin-left: 65px !important;
    display: flex;
    position: fixed;
}
.mobile__clz {
    margin-left: 0px !important;
    display: flex;
}
.search__clz {
    display: d-none;
}
.search-icon__clz {
    fill: #161617 !important;
    position: absolute;
    z-index: 10;
    left: 10px;
    width: 20px !important;
}
.vertical-menu__clz {
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 70px;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    text-align: left;
    display: inline-block;
}
.main-content {
    margin-left: 274px;
    overflow: hidden;
}
.footer {
    position: fixed;
    right: 0;
    color: #212529;
    left: 250px !important;
    background-color: #efeff5;
    display: flex;
}

.navbar-brand-box__clz {
    width: 273px !important;
}
.main-content__clz {
    margin-left: 272px !important;
    overflow: hidden !important;
}

.border-none__clz {
    border: none !important;
}

.modal-content {
    width: 1500px;
}

.form-group {
    margin-bottom: 0rem !important ;
}
.styled-input-wrapper__clz > div {
    margin-bottom: 0px !important ;
}
.btn_plus {
    border: 1px solid #ced4da;
    border-radius: 6px;
}
.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: unset;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

.PhoneInputInput {
    width: 170px;
}

.required__clz:after {
    content: " *";
    color: red;
}

.card-body__clz.card-body {
    background-color: white !important;
    height: 463px;
    overflow-y: auto;
}

.text-danger {
    color: black !important;
}

.card {
    margin-bottom: 3px !important;
}


.Toastify__toast {
    width: -webkit-max-content;
    width: max-content;
}

.disabled_clz {
    pointer-events: none;
    opacity: 0.4;
}

.invalid__clz {
    border-color: #eb2020 !important;
    border-style: solid;
    border-width: 1px;
}

.react-tel-input .form-control {
    width: 230px !important;
}

td {
    justify-content: center !important;
}

tr th:nth-child(1) {
    width: 50%;
}

.form-group {
    margin-bottom: 0rem !important ;
}
.styled-input-wrapper__clz > div {
    margin-bottom: 0px !important ;
}
.btn_plus {
    border: 1px solid #ced4da;
    border-radius: 6px;
}
.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: unset;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

.PhoneInputInput {
    width: 170px;
}

.required__clz:after {
    content: " *";
    color: red;
}

.card-body__clz.card-body {
    background-color: white !important;
    height: 463px;
    overflow-y: auto;
}

.text-danger {
    color: black !important;
}

.card {
    margin-bottom: 3px !important;
}



.Toastify__toast {
    width: -webkit-max-content;
    width: max-content;
}

.disabled_clz {
    pointer-events: none;
    opacity: 0.4;
}

.invalid__clz {
    border-color: #eb2020 !important;
    border-style: solid;
    border-width: 1px;
}

.react-tel-input .form-control {
    width: 230px !important;
}

.btn_ {
    color: #000000 !important;
    background-color: #ffffff;
    border-color: #74788d !important;
    border: 1px solid transparent;
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}
.arrowRight {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(320deg);
            transform: rotate(320deg);
}
.arrowDown {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.makeStyles-tableHeadCell-4 {
    color: rgba(0, 0, 0, 0.87);
    padding: 16px 1px 16px 16px;
    font-size: 0.875rem;
    text-align: left;
    font-weight: 500;
    line-height: 1.5rem;
    border-right: 1px solid rgba(224, 224, 224, 1);
    vertical-align: inherit;
    font-weight: bold;
}
/* table {
    overflow-x: auto;
} */
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}



.MuiTableBody-root {
    overflow-y: auto !important;
    overflow-x: auto !important;
    display: block !important;
    height: 445px ;
}

.MuiTableCell-root {
    display: flex !important;
    justify-content: center !important;
    flex: auto !important;
}

.css-idl2bg-MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow-x: auto;
    overflow-y: hidden;
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-bottom: 1rem;
    margin-top: 0 !important;
    padding-top: 0 !important;
    max-height: calc(100vh - (370px + 125px));
    height: 445px;
}
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

#nprogress .bar,
#nprogress .spinner {
    z-index: 999999 !important;
}
.password-toggle__2 {
    position: absolute;
    padding-top: 18px !important;
    right: 26px;
    opacity: 0.6;
}
.password-toggle__1 {
    position: absolute;
    right: 26px;
    opacity: 0.6;
}
.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}

@media (max-width: 576px) {
    .login-logo__clz {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 35vh;
    }

    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        top: 5%;
        position: absolute;
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
                clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
        width: 100%;
        height: 100vh;
    }

    .video {
        width: auto;
        height: 100vh;
    }

    .login-card {
        top: 60% !important;
        left: 50% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .login-logo__clz {
        margin-left: 28vw;
        margin-top: 2vh;
        margin-bottom: 4vh;
        width: 35vh;
    }

    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        width: 80%;
        top: 2%;
        left: 10%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
                clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
        width: 100%;
        height: 100vh;
    }

    .video {
        width: auto;
        height: 100vh;
    }

    .login-card {
        top: 55% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }

    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }

    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }

    .video {
        width: auto;
        height: 100%;
    }

    .login-card {
        top: 60% !important;
        left: 55% !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }

    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }

    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }

    .video {
        width: auto;
        height: 100vh;
    }

    .login-card {
        top: 55% !important;
        left: 55% !important;
    }
}

@media (min-width: 1200px) {
    .login-logo__clz {
        margin-left: 4vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }

    .slogan {
        margin-left: 10vw;
        width: 40vh;
        margin-top: 3vh;
    }

    .etk-logo__clz {
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
        width: 30%;
    }

    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 40vw;
    }

    .video {
        width: 120%;
        height: auto;
    }

    .login-card {
        left: 60% !important;
    }
}

.login-btn__clz {
    box-shadow: 0px 15px 20px 7px #bfbdbd !important;
}

.login-btn__clz:hover {
    background-color: white !important;
    color: black !important;
}

.login-card {
    font-family: "Poppins";
    left: 50%;
    top: 50%;
    width: 30%;
    height: 47%;
}

.login-card h3 {
    font-size: 35px;
}

.login-card .form-group label {
    font-size: 20px;
}

.password-toggle__clz {
    position: absolute;
    padding-top: 38px;
    right: 15px;
    opacity: 0.6;
}

.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}

.submit-button__clz {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    margin-left: 31%;
}

input::-webkit-input-placeholder {
    width: 100% !important;
}

input:-ms-input-placeholder {
    width: 100% !important;
}

input::placeholder {
    width: 100% !important;
}
