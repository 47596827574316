.form-group {
    margin-bottom: 0rem !important ;
}
.styled-input-wrapper__clz > div {
    margin-bottom: 0px !important ;
}
.btn_plus {
    border: 1px solid #ced4da;
    border-radius: 6px;
}
.inznaY {
    border: unset;
    background: transparent;
    color: #2b2828;
    padding: 0px !important;
    margin: 0px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: unset;
    transition: unset;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

.PhoneInputInput {
    width: 170px;
}

.required__clz:after {
    content: " *";
    color: red;
}

.card-body__clz.card-body {
    background-color: white !important;
    height: 463px;
    overflow-y: auto;
}

.text-danger {
    color: black !important;
}

.card {
    margin-bottom: 3px !important;
}



.Toastify__toast {
    width: max-content;
}

.disabled_clz {
    pointer-events: none;
    opacity: 0.4;
}

.invalid__clz {
    border-color: #eb2020 !important;
    border-style: solid;
    border-width: 1px;
}

.react-tel-input .form-control {
    width: 230px !important;
}
