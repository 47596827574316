.btn_ {
    color: #000000 !important;
    background-color: #ffffff;
    border-color: #74788d !important;
    border: 1px solid transparent;
    text-align: left;
    font: normal normal 300 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}
.arrowRight {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    width: 8px;
    height: 8px;
    transform: rotate(320deg);
}
.arrowDown {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
}
.makeStyles-tableHeadCell-4 {
    color: rgba(0, 0, 0, 0.87);
    padding: 16px 1px 16px 16px;
    font-size: 0.875rem;
    text-align: left;
    font-weight: 500;
    line-height: 1.5rem;
    border-right: 1px solid rgba(224, 224, 224, 1);
    vertical-align: inherit;
    font-weight: bold;
}
/* table {
    overflow-x: auto;
} */
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}



.MuiTableBody-root {
    overflow-y: auto !important;
    overflow-x: auto !important;
    display: block !important;
    height: 445px ;
}

.MuiTableCell-root {
    display: flex !important;
    justify-content: center !important;
    flex: auto !important;
}

.css-idl2bg-MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow-x: auto;
    overflow-y: hidden;
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-bottom: 1rem;
    margin-top: 0 !important;
    padding-top: 0 !important;
    max-height: calc(100vh - (370px + 125px));
    height: 445px;
}