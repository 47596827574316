#loading {
  display: inline-block;
  color: rgba(68, 185, 252, 0.3);
  width: 50px;
  height: 50px;
  border: 3px solid rgba(68, 185, 252, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.centeranimation {
  display: flex;
  flex-direction: row;
  margin-top: 20%;
  justify-content: center;
}
