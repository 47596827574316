body,
html {
    /* 👇️ or use Operating system default fonts 👇️ */
    font-family: "Segoe UI";
}



.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #eeeeee;
    box-shadow: "0px 1px 3px #616060c6";
}
.bodycard {
    padding-top: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-right: 0px !important;
    margin-left: 0px !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    overflow-y: auto;
    min-height: calc(-446px + 100vh) !important;
    max-height: calc(-446px + 100vh) !important;
}



.css-1ex1afd-MuiTableCell-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 16px !important;
    color: rgba(0, 0, 0, 0.87);
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: white !important;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: white !important;
    border-top: 1px solid rgba(0, 0, 0, .125);
}

.MuiTableContainer-root {
    overflow-y: auto !important;
    padding-bottom: 0rem !important;
    min-height: 0px;
    overflow-x: auto !important;
}


.jss8 {
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    padding: 8px 16px;
    font-size: 0.875rem;
    text-align: center;
    align-items: center;
    font-family: "Segoe UI", "-apple-system", "Helvetica Neue", "Arial";
    font-weight: 300;
    line-height: 1.3;
    vertical-align: inherit;
    justify-content: center;
    height: 58px;
}


.MuiTableBody-root {
    overflow-y: auto !important;
    overflow-x: auto !important;
    display: block !important;
    max-height: 394px !important;
}
.Headercard {
    background-color: white !important;
    height: 66px !important;
}